<template>
    <div class="space-y-5">
        <div class="w-full">
            <div class="vx-row items-end">
                <div class="vx-col">
                <label for="dateDebut" class="text-sm">Date de début</label>
                <flat-pickr v-model.lazy="dateDebut" :config="configdateTimePicker" class="w-full" name="dateDebut" />
                </div>
                <div class="vx-col">
                <label for="dateFin" class="text-sm">Date de fin</label>
                <flat-pickr v-model.lazy="dateFin" :config="configdateTimePicker" class="w-full" name="dateFin" />
                </div>
                <div class="vx-col">
                <vs-select v-if="principale" label="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine">
                    <vs-select-item  class="w-full" value="Tout" text="Tout" />
                    <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
                </vs-select>
                </div>
                <div class="vx-col">
                    <vs-button ref="loadableButton" id="button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-search">Valider</vs-button>
                </div>
            </div>
        </div>
        <div>
            <h2 class="text-lg font-medium text-gray-900 dark">
                
            </h2>
            <e-charts autoresize :options="config" ref="chart_client" theme="ovilia-green" auto-resize />
        </div>
    </div>
</template>
<script>
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
import {French as FrenchLocale} from 'flatpickr/dist/l10n/fr.js'
import { mapGetters, mapActions } from 'vuex'
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import theme from './theme.json'

ECharts.registerTheme('ovilia-green', theme)

export default {
  name: 'EchartStock',
  components: {
    flatPickr,
    ECharts
  },
  data () {
    return {
      activeUserInfos: null,
      idOfficine: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null,
      configdateTimePicker: { locale: FrenchLocale, dateFormat: 'd F Y' },
      dateDebut: null,
      dateFin: null,
      config: null
    }
  },
  computed: {
    ...mapGetters('officine', ['officineIsPrincipale']),
    // Utilisez une méthode pour obtenir la valeur en fonction du localStorage
    userOfficineId () {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      return userInfo ? userInfo.IdOfficine : null
    },
    // Utilisez le getter avec l'argument
    principale () {
      return this.officineIsPrincipale(this.userOfficineId)
    }
  },
  methods: {
    ...mapActions()
  }
}
</script>